.cr-modal {
  .modal-content {
    background: #ffffff;
    box-shadow: 0px 20px 25px rgba(0, 0, 0, 0.1),
      0px 10px 10px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    border: 0px;
  }

  .modal-body {
    color: #424242;
  }

  .modal-footer {
    border-top: unset;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0px;
    padding-bottom: 1rem;
  }

  &__header {
    display: flex;
    align-items: center;
    border-radius: 8px 8px 0px 0px;

    :first-letter {
      text-transform: uppercase;
    }

    .modal-title {
      max-width: 90%;
      font-size: 1rem;
      font-weight: 500;
    }

    .close {
      max-width: 10%;
    }
  }
}
