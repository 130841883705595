.cr-content-header {
  &__nav-right {
    flex-direction: row;
    justify-content: flex-end;
    margin-left: auto;
  }

  .nav {
    margin-top: 8px;
    .nav-item {
      padding-left: 10px;
      position: relative;
      height: 36px;
      width: 160px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border: 1px solid rgb(183, 184, 190);
      border-bottom: none;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      background: #e9ecef;

      &:hover {
        background-color: white;
      }

      .nav-link {
        color: black;
        padding: 0px;
      }
      .nav-link.active {
        background-color: white;
        color: #007bff;
      }

      .icon {
        position: absolute;
        right: 0px;
        cursor: pointer;
      }
    }

    .nav-item.active {
      background-color: white;

      .fa {
        color: #007bff;
      }
    }
  }
}
