@import url('https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');
// @import url(https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i);

/* Font Smoothing */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
td,
button,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.display-1,
.display-2,
.display-3,
.display-4,
.navbar,
.brand,
.alert {
  -moz-osx-font-smoothing: grayscale;
  // -webkit-font-smoothing: antialiased;
  // font-family: Poppins, Roboto, 'Helvetica Neue', Arial, sans-serif;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: $font-weight-normal;
  font-size: 13px;
}

.cr-app {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: auto;
}

.container-fluid {
  width: auto;
}

.btn {
  font-size: 12px;
}

.btn.btn-primary {
  border-color: #007bff;
  background: #007bff;
}

.form-control {
  font-size: 13px;
}

.form-control.is-invalid {
  background-image: none;
}

.bg-light {
  background-color: white;
}

.error {
  color: red;
}

.page-link {
  color: #007bff;
}

.page-item.active .page-link {
  background-color: #007bff;
  border-color: #007bff;
}

.pnl-total {
  font-size: 15px;
  font-weight: 700;
}

.info-icon-tooltip {
  cursor: pointer;
  color: #656565;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: #ffcc10 !important;
  }

  .tooltip-overlay {
    max-width: 300px;
    opacity: 1 !important;
  }
}

.title{
  font-size: 18px;
  font-weight: 500;
  margin-top: 5px;
}


