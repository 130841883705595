.cr-sidebar {
  background-color: #e8e9ea;
  border-right: 1px solid #dee2e6;
  position: fixed;
  top: $cr-header-height;
  bottom: 0;
  left: -$cr-sidebar-width;
  z-index: 5;
  width: $cr-sidebar-width;
  font-weight: 200;
  color: #fff;
  background-position: center center;
  background-size: cover;
  // transition: left 0.1s ease-in;

  + .cr-content {
    // transition: margin 0.1s ease-in;
  }

  &::after,
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: block;
    width: 100%;
    height: 100%;
    content: '';
  }

  &::after {
    z-index: 3;
    opacity: 1;
  }

  &[data-image]:after {
    opacity: 0.77;
  }

  &__background {
    //background-color: white;
  }

  &__content {
    width: $cr-sidebar-content-width;
    color: black;
    background-color: white;
    position: relative;
    z-index: 4;
    height: 100%;
    padding-bottom: 30px;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: block;
    height: 100%;
    background-position: center center;
    background-size: cover;
  }

  &__nav-item-collapse {
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }

  &__nav-item-icon {
    width: 1.5rem;
    height: 1.5rem;
    padding-right: 0.5rem;
  }

  &--open {
    left: 0;

    + .cr-content {
      max-width: calc(100vw - 190px);
      margin-left: $cr-sidebar-width;
      // transition: margin 0.2s ease-in;
    }
  }

  .nav {
    margin-top: 10px;
    .nav-item {
      margin: 5px 10px;
      //color: theme-color(light);
      border-radius: 4px;
      opacity: 0.86;

      .first-letter-uppercase {
        text-transform: capitalize;
      }

      .sub-menu-item {
        padding-left: 2rem;
        text-transform: capitalize;
      }

      .nav-link {
        color: black;
      }
      .nav-link.active {
        background: rgba(0, 0, 0, 0.05);
      }
    }

    .nav-link {
      span {
        font-size: 14px;
      }

      .fa-chevron-left,
      .fa-chevron-down {
        font-size: 10px;
      }
      padding: 0.5rem 3px;

      &:hover {
        background: rgba(theme-color(light), 0.15);
      }
    }
  }
}
