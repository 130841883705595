.cr-monitoring-table {
  width: auto !important;
  td {
    padding: 0 !important;
  }
  th.data-column {
    min-width: 200px;
  }
  .symbol-cell {
    padding: 19px;
  }
  .cell {
    font-size: 15px;
    .fas {
      font-size: 11px;
    }
    .fa-chevron-up {
      color: green;
    }
    .fa-chevron-down {
      color: red;
    }

    .middle {
      font-size: 18px;
      font-weight: 700;
    }

    .arrow-section {
      padding-bottom: 5px;
      margin-left: 5px;
      margin-right: 5px;
    }

    width: 50%;
    height: 100%;
  }
  .data-cell {
    height: 42px;
  }
  .date-time-cell {
    height: 30px;
    border-top: 1px solid #e9ecef;
  }
  .cell.ask {
    border-right: 1px solid #e9ecef;
  }

  @media screen and (max-width: 1600px) {
    .cell {
      font-size: 13px;
    }

    .date-time-cell {
      font-size: 13px;
    }

    th.data-column {
      width: 164px;
    }
  }
}
