.cr-modal-value-type {
  max-width: 800px !important;
  .modal-content {
    background: #ffffff;
    box-shadow: 0px 20px 25px rgba(0, 0, 0, 0.1),
      0px 10px 10px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    border: 0px;
  }

  .modal-body {
    color: #424242;
  }

  &__header {
    display: block;
    border-radius: 8px 8px 0px 0px;

    :first-letter {
      text-transform: uppercase;
    }

    .modal-title {
      max-width: 90%;
    }

    .close {
      max-width: 10%;
      margin-top: -45px;
    }
  }

  .first-letter-upper {
    :first-letter {
      text-transform: uppercase;
    }
  }
}
