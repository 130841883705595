.cr-header {
  position: fixed;
  height: 35px;
  width: 100%;
  background-color: rgb(248, 249, 250);
  background-image: linear-gradient(to bottom, #fff 0, #f8f8f8 100%);
  z-index: 1030;
  border-bottom-color: rgb(231, 231, 231);
  border-left-color: rgb(231, 231, 231);
  border-right-color: rgb(231, 231, 231);
  border-top-color: rgb(231, 231, 231);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 2px 0px;
  box-sizing: border-box;

  .title {
    margin-left: 15px;
    font-size: 16px;
    font-weight: 400;
  }

  .sidebar-icon {
    cursor: pointer;
  }

  .logout-section {
    a {
      font-size: 15px;
      color: rgb(33, 37, 41);
      text-decoration: none;
    }
  }
}
