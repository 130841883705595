.load {
  &.full-screen {
    background-color: rgba(18, 27, 33, 0.3);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
  }

  &.small .loading-dots--dot {
    height: 5px;
    width: 5px;
  }
}

.loaderIc {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// .loaderIc img {
//   animation: spin 2s linear infinite;
//   transition: 0.2s ease;
//   -moz-transition: 0.2s ease;
//   -webkit-transition: 0.2s ease;
//   width: 160px;
//   height: 160px;
// }

.loading-dots {
  text-align: center;
  width: 100%;
}

.loading-dots--dot {
  -webkit-animation: dot-keyframes 1.5s infinite ease-in-out;
  animation: dot-keyframes 1.5s infinite ease-in-out;
  background-color: #007bff;
  border-radius: 10px;
  display: inline-block;
  height: 10px;
  width: 10px;
  margin: 0 4px;
}

.loading-dots--dot:nth-child(2) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.loading-dots--dot:nth-child(3) {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

@-webkit-keyframes dot-keyframes {
  0% {
    opacity: 0.4;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
  }
  100% {
    opacity: 0.4;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@keyframes dot-keyframes {
  0% {
    opacity: 0.4;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
  }
  100% {
    opacity: 0.4;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
