// page
.cr-page {
  max-height: calc(100vh - 79px);
  overflow-y: auto;

  &__header {
    height: 39px;
    background-image: linear-gradient(to bottom, #f5f5f5 0, #e8e8e8 100%);
    background-repeat: repeat-x;
    border-top: 1px solid rgba(0, 0, 0, 0.125);
    border-left: 1px solid rgba(0, 0, 0, 0.125);
    border-right: 1px solid rgba(0, 0, 0, 0.125);
    border-top-right-radius: 5px;
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: flex-start;
    }
    display: flex;
    align-items: center;
    background-color: #e9ecef;

    .add-entity-button {
      align-self: flex-start;
      margin-left: auto;
    }
  }

  &__title {
    @include media-breakpoint-down(sm) {
      margin-bottom: 0;
    }
    padding-right: 0.5rem;
    margin-bottom: 1rem;
  }

  &__breadcrumb {
    @include media-breakpoint-down(sm) {
      align-self: flex-start;
    }

    text-transform: capitalize;

    .breadcrumb {
      padding: 6px 9px;
      margin-bottom: 0px;
      background-color: unset;
      font-weight: 700;

      .breadcrumb-item.active {
        color: black;
      }
    }
  }

  .css-2b097c-container,
  .css-14jk2my-container
   {
    width: 200px;
    margin-left: 10px;
  }

  .row {
    .col,
    > [class^='col-'] {
      margin-bottom: 1rem;
    }
  }
}

.cr-page-spinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-wrapper {
  overflow: auto;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  table {
    border-left: unset;
    border-right: unset;
  }

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    height: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}

.market-maker-table,
.activity-generator-table {
  .processor-info {
    min-width: 200px;
    max-width: 200px;
  }
}

.market-maker-table,
.activity-generator-table,
.feed-generator-table {
  .actions {
    min-width: 240px;
  }
}

.hedging-exchange-keys-table{
  .api-key{
    width: 225px;
  }
  .actions {
    width: 225px;
  }
}