.cr-content {
  overflow-y: hidden;
  padding-top: $cr-header-height;
  display: inline-flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100vh;
  max-width: calc(100vw - 13px);
  padding-right: 0px;
  padding-left: 2px;
  margin-left: 13px;
  max-height: calc(100vh);

  .filter {
    position: relative;
    .date-picker-section {
      position: relative;
      .date-picker-error {
        position: absolute;
        top: 25px;
        left: 0px;
      }
    }

    .filter-section-error {
      position: absolute;
      top: 48px;
    }
  }
}
